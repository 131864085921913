import React, { useMemo } from "react";
import { Chip } from "@mui/material";

function camelCaseToWords(s) {
	const result = s.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

function flattenObj(ob) {
	// The object which contains the
	// final result
	const result = {};

	// loop through the object "ob"
	// eslint-disable-next-line no-restricted-syntax
	for (const i in ob) {
		// We check the type of the i using
		// typeof() function and recursively
		// call the function again
		if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
			const temp = flattenObj(ob[i]);
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const j in temp) {
				// Store temp in result
				result[`${i}.${j}`] = temp[j];
			}
		}

		else if (Array.isArray(ob[i])) {
			const temp = flattenObj(ob[i]);
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const j in temp) {
				// Store temp in result
				result[`${i}.${j}`] = temp[j];
			}
		}

		// Else store ob[i] in result directly
		else {
			result[i] = ob[i];
		}
	}
	return result;
}

function displayType(value) {
	if (value === 'true' || value === 'false') return value === 'true' ? "Sì" : "No";
	return value;
}

const filteredKeys = ['page', 'pageSize', 'queryParameters', 'enteOperatore'];

export default function FiltersViewer({ filters, setFilters }) {
	const boxStyle = useMemo(() => ({
		background: 'white',
		borderRadius: '5px',
		width: '100%',
		padding: '5px 10px',
		display: 'flex',
		flexDirection: 'row',
		columnGap: '10px',
		rowGap: '5px',
		flexWrap: 'wrap'
	}), []);

	const flattenFilters = useMemo(() => flattenObj(filters), [filters]);
	const filteredFilters = useMemo(() => Object.keys(flattenFilters)
		.filter((k) => !filteredKeys.some((bk) => k.includes(bk)) && flattenFilters[k] !== 'null'), [flattenFilters]);

	return (
		<div style={boxStyle}>
			{filteredFilters.map((k) => (
				<Chip
					label={(
						<span>
							{camelCaseToWords(k.split('.').pop())}
							:
							<b>
								{` ${displayType(flattenFilters[k])}`}
							</b>
						</span>
					)}
					onDelete={filteredFilters.length > 1 && !k.includes("inizioCreazione") && (() => {
						const fields = k.split('.');
						let fieldObj = filters;
						while (fields.length > 1) {
							fieldObj = fieldObj[fields.shift()];
						}
						fieldObj[fields.shift()] = null;

						setFilters(filters);
					})}
				/>
			))}
		</div>
	);
}
