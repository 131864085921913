/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import {
	Checkbox,
	FormControl, FormControlLabel,
	InputLabel,
	MenuItem,
	Select, Switch,
	TextField
} from '@mui/material';
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useFetch } from '../../Hooks/useFetch';
import SmallDataTable from '../Components/Tables/SmallDataTable';
import DataTableColumn from '../Components/Tables/DataTableColumn';
import ToolsArea from '../Components/ToolsArea';
import DeleteItem from "../Components/DeleteItem";
import EditModForm, { EditFormContext } from "../Components/Forms/EditModForm";
import EnteSelect from "../Components/Forms/EnteSelect";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import TributiAmmessi from "../Components/Forms/TributiAmmessi";
import SpinnyPage from "../Components/SpinnyPage";
import useTableColumns from "../Utils/useTableColumns";
import { DataGridSelectColumn } from "../Components/Tables/DataGridSelect";
import DataGrid from "../Components/Tables/DataGrid";


function filterStruct(data) {
	if (data === null || data === undefined) return data;

	if (Array.isArray(data)) {
		return data.map((k) => filterStruct(k));
	}

	if (typeof data === 'object') {
		if (Object.keys(data).every((k) => data[k] == null)) return null;
		const returnData = {};
		Object.keys(data).forEach((k) => {
			returnData[k] = filterStruct(data[k]);
		});
		return returnData;
	}

	return data;
}

const Tributi = ({ control, ente }) => {
	const { data: tributi, status } = useFetch(`/tributo/${ente}/list`, null, 'GET');

	const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
	const previousState = useRef("");

	useEffect(() => {
		if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
		if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
		if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
		previousState.current = status;
	}, [status]);

	if (!tributi) return (<></>);

	return tributi?.data?.length > 0 ? (
		<div className="mt-3">
			<TributiAmmessi
				control={control}
				trb={ente ? tributi?.data.map((t) => t.codiceTributo) : []}
			/>
		</div>
	) : (<></>);
};

export default function Operatori({ account }) {
	const enteOperatore = account.ente?.codiceEnte;
	const adminSistema = account.ruolo.codice === "AMMINISTRATORE";

	// - Table data
	const [selectedRows, setSelectedRows] = useState(new Map());
	const [refresh, setRefresh] = useState(0);
	const [query, setQuery] = useState({ page: 0, pageSize: 20 });
	const { data } = useFetch(enteOperatore ? '/operatore/query' : '/operatore/query/allUsers', null, 'POST', filterStruct(query), refresh);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [backofficeQuery, setBackofficeQuery] = useState(false);
	const [createEditMode, setCreateEditMode] = useState(false);
	const [selectedOperatore, setSelectedOperatore] = useState(null);
	const [queryStrutture, setQueryStrutture] = useState({ page: 0, pageSize: 5 });

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("operatori", [
		DataGridSelectColumn,
		!enteOperatore && { key: "codiceEnte", name: "Codice ente", frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "codiceFiscale", name: "Codice fiscale", sortColumn: "intestatario.codiceFiscale", sortable: true, frozen: false /*true*/, draggable: false, resizable: false, highlight: true, minWidth: 200, width: 200 },
		{ key: "nome", name: "Nome", sortColumn: "intestatario.nome", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 150, width: 150 },
		{ key: "cognome", name: "Cognome", sortColumn: "intestatario.cognome", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 150, width: 150 },
		{ key: "ruolo", name: "Ruolo", frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 150, width: 150 },
		{ key: "email", name: "Email", sortColumn: "intestatario.email", sortable: true, frozen: false, draggable: true, resizable: true, highlight: true, minWidth: 200, width: 200 },
		{ key: "ultimoAccesso", name: "Ultimo accesso", sortColumn: "ultimoAccesso", sortable: true, frozen: false, draggable: true, resizable: true, minWidth: 150, width: 150 },
		{ key: "descrizione", name: "Descrizione", sortColumn: "descrizione", sortable: true, frozen: false, draggable: true, highlight: true, resizable: true }
	], [DataGridSelectColumn.key, !enteOperatore && "codiceEnte", "codiceFiscale", "nome", "cognome", "ruolo", "email", "ultimoAccesso", "descrizione"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = data?.data?.map((r) => ({
		id: r.id,
		codiceEnte: r.codiceEnte ?? "",
		codiceFiscale: r.intestatario.codiceFiscale ?? "",
		nome: r.intestatario.nome ?? "",
		cognome: r.intestatario.cognome ?? "",
		ruolo: r.tipo ?? "",
		email: r.intestatario.email ?? "",
		ultimoAccesso: r.ultimoAccesso ? moment(r.ultimoAccesso).format("DD/MM/yyyy - HH:ss") : "--Nessun accesso--",
		descrizione: r.descrizione ?? ""
	}));

	// - Prendo il token JWT
	const jwt = useToken();

	useEffect(() => {
		const action = async () => {
			if (selectedRows.size === 1 && createEditMode === "edit") {
				const operatore = data.data.filter((d) => d.id === [...selectedRows][0])[0];

				const response = await fetch(`${configuration.serverAddress}/operatore/${operatore.id}`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				const operatoreData = await response.json();
				setSelectedOperatore(() => ({
					...operatoreData,
					codiceEnte: operatoreData?.ente?.codiceEnte
				}));
			} else setSelectedOperatore(null);
		};
		action();
	}, [createEditMode]);

	if (!data) return <SpinnyPage />;

	const TipoOperatore = ({ control, nullItem, required, backoffice, defaultValue, search }) => {
		const tipoOperatore = (value) => {
			if (value === undefined && nullItem) return "";
			if (search) return value;
			if (value === undefined && backoffice) return "AMMINISTRATORE";
			if (value === undefined && !backoffice) return "OPERATORE_ENTE";
			if (backoffice && !["INTERESSI_LEGALI", "AMMINISTRATORE"].includes(value)) return "AMMINISTRATORE";
			if (!backoffice && !["OPERATORE_ENTE", "ADMIN_ENTE", "OSSERVATORE_ENTE"].includes(value)) return "OPERATORE_ENTE";
			return value;
		};

		return (
			<FormControl variant="standard" className="w-100">
				<InputLabel id="ruolo">Ruolo operatore</InputLabel>
				<Controller
					name="ruolo.codice"
					defaultValue={defaultValue}
					control={control}
					render={({ field: { onChange, value, ref } }) => (
						<Select
							labelId="tipoEnte-label"
							id="ruolo"
							required={required}
							label="Ruolo operatore"
							inputRef={ref}
							inputProps={{ value: tipoOperatore(value) }}
							onChange={onChange}
						>
							{(nullItem || search) && <MenuItem defaultChecked value="">Nessun filtro</MenuItem>}
							{(backoffice || search) && <MenuItem defaultChecked value="INTERESSI_LEGALI">Gestione interessi legali</MenuItem>}
							{(backoffice || search) && <MenuItem defaultChecked value="AMMINISTRATORE">Amministratore piattaforma</MenuItem>}
							{(!backoffice || search) &&	<MenuItem defaultChecked value="OSSERVATORE_ENTE">Osservatore</MenuItem>}
							{(!backoffice || search) && <MenuItem defaultChecked value="OPERATORE_ENTE">Operatore</MenuItem>}
							{(!backoffice || search) &&	<MenuItem defaultChecked value="ADMIN_ENTE">Amministratore</MenuItem>}
						</Select>
					)}
				/>
			</FormControl>
		);
	};

	const editCreateForm = ({ register, control, mode, setValue }) => {
		const ente = enteOperatore ?? useWatch({ name: 'codiceEnte', control });
		const role = useWatch({ name: 'ruolo.codice', control });
		const destinatarioReport = useWatch({ name: 'destinatarioReport', control });
		const operatoreSistema = useWatch({ name: 'ruolo.codice', control }) === "AMMINISTRATORE";
		const backoffice = !enteOperatore && ["AMMINISTRATORE", "INTERESSI_LEGALI"].includes(role);

		const { data: strutture, status } = useFetch('/struttura/list', ente, 'GET');

		const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
		const previousState = useRef("");

		useEffect(() => {
			if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
			if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
			if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
			previousState.current = status;
		}, [status]);

		useEffect(() => setBackofficeQuery(backoffice || !ente), [backoffice, ente]);

		const calculateStructDefault = () => strutture?.data.filter((x) => (
			x.codiceEnte === ente
		)).reduce((acc, r) => {
			const id = r.cdr.replaceAll('.', '-');
			return { ...acc, [id]: {
				enabled: r.enabled ?? false,
				modificaSubordinate: r?.modificaSubordinate ?? false,
				modificaSovraordinate: r?.modificaSovraordinate ?? false,
				ruolo: r?.ruolo ?? "Operatore"
			} };
		}, {});

		const struttureFiltered = strutture?.data
			.slice(queryStrutture.page * queryStrutture.pageSize,
				queryStrutture.page * queryStrutture.pageSize + queryStrutture.pageSize) ?? [];

		useEffect(() => {
			if (mode !== "edit") setValue("strutture", calculateStructDefault());
		}, [ente, strutture?.data]);

		useEffect(() => {
			if (mode !== "edit") {
				if (backoffice) setValue("ruolo.codice", "AMMINISTRATORE");
				else setValue("ruolo.codice", "OPERATORE_ENTE");

				if (selectedOperatore) setValue("destinatarioReport", selectedOperatore.destinatarioReport);
				else setValue("destinatarioReport", false);
			}
		}, [backoffice, mode]);

		if (!strutture) return <SpinnyPage />;

		return (
			<div className="flex-column d-flex">
				<div className="flex-row d-flex">
					<div className="w-100">
						<TextField required {...register('intestatario.codiceFiscale')} disabled={mode === 'edit'} label="Codice fiscale" inputProps={{ maxLength: 30 }} variant="standard" className="mt-2 w-100" />
						<div className="w-100 mt-3">
							{adminSistema
								&& (
									<FormControlLabel
										control={(
											<Switch
												onChange={(e) => setValue("ruolo.codice", e.target.checked ? "AMMINISTRATORE" : "ADMIN_ENTE")}
												disabled={mode === 'edit'} checked={role === "AMMINISTRATORE"} />
										)}
										label="Operatore Sistema"
									/>
								)
							}
							<FormControlLabel
								control={
									<Switch onChange={(e) => setValue("destinatarioReport", e.target.checked)} checked={destinatarioReport} />
								}
								label="Destinatario Reportistica"
							/>
						</div>
						{
							!enteOperatore && !backoffice && (
								<Controller name="codiceEnte" rules={{ required: mode !== 'edit' }} control={control} render={({ field: { onChange, value } }) => <EnteSelect onChange={onChange} value={value} required disabled={mode === 'edit'} />} />
							)
						}
						<TextField required {...register('intestatario.nome')} label="Nome" inputProps={{ maxLength: 256 }} variant="standard" className="mt-2" style={{ width: '180px' }} id="cognome" />
						<TextField required {...register('intestatario.cognome')} label="Cognome" inputProps={{ maxLength: 256 }} variant="standard" className="mt-2 ml-3" style={{ width: '180px' }} id="nome" />
						<TextField required {...register('intestatario.email')} label="Email" inputProps={{ maxLength: 256 }} variant="standard" className="mt-2 w-100" id="email" />
						<div className="mt-2">
							<TipoOperatore control={control} backoffice={backoffice} defaultValue={backoffice ? 'AMMINISTRATORE' : 'OPERATORE_ENTE'} required />
						</div>
						{!backoffice && ente && <Tributi control={control} ente={ente} />}
					</div>
					<div className="pl-5 w-100">
						<TextField {...register('intestatario.indirizzo')} inputProps={{ maxLength: 256 }} label="Indirizzo" variant="standard" className="mt-2 w-50" id="indirizzo" />
						<TextField {...register('intestatario.numeroCivico')} inputProps={{ maxLength: 6 }} label="N Civico" variant="standard" className="mt-2 w-25 ml-3" id="numeroCivico" />
						<TextField {...register('intestatario.comune')} inputProps={{ maxLength: 50 }} label="Comune" variant="standard" className="mt-2 w-50" id="comune" />
						<TextField {...register('intestatario.provincia')} inputProps={{ maxLength: 2 }} label="Provincia" variant="standard" className="mt-2 w-25 ml-3" id="provincia" />
						<TextField {...register('intestatario.cap')} inputProps={{ maxLength: 5 }} label="CAP" variant="standard" className="mt-2 w-100" id="cap" />
						<TextField {...register('intestatario.nazione')} inputProps={{ maxLength: 50 }} label="Nazione" variant="standard" className="mt-2 w-100" id="nazione" />
						<TextField {...register('intestatario.telefono')} inputProps={{ maxLength: 20 }} label="Telefono" variant="standard" className="mt-2 w-100" id="telefono" />
						<TextField {...register('intestatario.cellulare')} inputProps={{ maxLength: 20 }} label="Cellulare" variant="standard" className="mt-2 w-100" id="cellulare" />
					</div>
				</div>
				<div className="mt-4">
					{
						!operatoreSistema && strutture.data && strutture.data.filter((x) => x.codiceEnte === ente).length > 0
						&& (
							<SmallDataTable
								onPageChange={(page) => setQueryStrutture({ ...queryStrutture, page })}
								page={queryStrutture.page}
								data={struttureFiltered}
								pageSize={queryStrutture.pageSize}
								totalCount={strutture.totalResults}
								idField="cdrEnte"
								childrenField="figli"
							>

								<DataTableColumn
									renderHeader={() => "CDR"}
									renderContent={(r) => r.cdr}
									indentSize={10}
									padding={10}
									min
								/>

								<DataTableColumn
									renderHeader={() => ""}
									renderContent={(r) => (
										<Controller
											name={`strutture.${r.cdr.replaceAll('.', '-')}.enabled`}
											control={control}
											defaultValue={false}
											render={({ field: { onChange, value } }) => (
												<Checkbox onChange={onChange} checked={value} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />
											)}
										/>
									)}
									indentSize={0}
									padding={10}
									min
								/>

								<DataTableColumn renderHeader={() => "Denominazione"} renderContent={(r) => r.nome} indentSize={0} padding={10} />

								<DataTableColumn
									// eslint-disable-next-line
									renderHeader={() => <div align="center">SUB</div>}
									renderContent={(r) => (
										<Controller
											name={`strutture.${r.cdr.replaceAll('.', '-')}.modificaSubordinate`}
											control={control}
											defaultValue={false}
											render={({ field: { onChange, value } }) => (
												<Checkbox onChange={onChange} checked={value} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />
											)}
										/>
									)}
									indentSize={0}
									padding={10}
									min
								/>

								<DataTableColumn
									// eslint-disable-next-line
									renderHeader={() => <div align="center">SOVRA</div>}
									renderContent={(r) => (
										<Controller
											name={`strutture.${r.cdr.replaceAll('.', '-')}.modificaSovraordinate`}
											control={control}
											defaultValue={false}
											render={({ field: { onChange, value } }) => (
												<Checkbox onChange={onChange} checked={value} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />
											)}
										/>
									)}
									indentSize={0}
									padding={10}
									min
								/>


								<DataTableColumn
									renderHeader={() => ""}
									renderContent={(r) => (
										<FormControl variant="standard" className="w-100">
											<Controller
												name={`strutture.${r.cdr.replaceAll('.', '-')}.ruolo`}
												control={control}
												defaultValue="Operatore"
												render={({ field: { onChange, value } }) => role !== "OSSERVATORE_ENTE" && (
													<Select
														labelId="tipoEnte-label"
														id="ruolo"
														label="Ruolo"
														style={{ width: "150px" }}
														disableUnderline
														value={value}
														onChange={onChange}
													>
														<MenuItem defaultChecked value="Operatore">Operatore</MenuItem>
														<MenuItem value="Amministratore">Amministratore</MenuItem>
													</Select>
												)}
											/>
										</FormControl>
									)}
									indentSize={0}
									padding={10}
									min
								/>
							</SmallDataTable>
						)
					}
				</div>
			</div>
		);
	};

	const FilterFields = [
		[
			(r) => (enteOperatore ? <></> : <TextField {...r('ente.codiceEnte')} label="Codice ente" variant="standard" inputProps={{ maxLength: 50 }} />),
			(r) => (<TextField {...r('intestatario.codiceFiscale')} label="Codice Fiscale" variant="standard" />),
			(r) => (<TextField {...r('intestatario.cognome')} label="Cognome" variant="standard" />),
			(r) => (<TextField {...r('intestatario.nome')} label="Nome" variant="standard" />),
			(r) => (<TextField {...r('intestatario.email')} label="Email" variant="standard" />),
			(r, control) => (<div style={{ width: '300px' }}><TipoOperatore nullItem control={control} search /></div>)
		]
	];

	const canModify = ["All", "GestioneOperatori"].some((p) => account.ruolo.permessi.includes(p));

	return (
		<div>
			<DeleteItem
				open={deleteOpen}
				query="/operatore/delete"
				entityNamePlural="operatori"
				entityNameSingle="operatore"
				items={[...selectedRows].map((item) => ({ id: item }))}
				onCancel={() => setDeleteOpen(false)}
				onDeleted={() => { setDeleteOpen(false); setRefresh(refresh + 1); setSelectedRows(new Set()); }}
				onError={() => setDeleteOpen(false)}
			/>
			<EditModForm
				path={backofficeQuery ? "/operatore/bo" : "/operatore"}
				entityName="Operatore"
				defaultValue={{ ruolo: { codice: backofficeQuery ? "AMMINISTRATORE" : "OPERATORE_ENTE" } }}
				ente={createEditMode === 'edit' ? data.data.filter((d) => d.id === [...selectedRows][0])[0].codiceEnte : null}
				mode={createEditMode}
				data={selectedOperatore}
				onCancel={() => setCreateEditMode(false)}
				onSuccess={() => { setCreateEditMode(false); setRefresh(refresh + 1); }}
				formElements={editCreateForm}
			/>
			<ToolsArea
				className="mt-4 mb-3"
				selected={[...selectedRows]}
				disableDelete={!canModify}
				disableEdit={!canModify}
				disableNew={!canModify}
				queryParameters={[
					'intestatario.nome', 'intestatario.cognome',
					'intestatario.codiceFiscale', 'intestatario.email', 'descrizione'
				]}
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				deleteCallback={() => setDeleteOpen(true)}
				createCallback={() => setCreateEditMode('create')}
				editCallback={() => setCreateEditMode('edit')}
				fields={FilterFields}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.id}
				totalResults={data.totalResults}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
