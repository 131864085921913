/* eslint-disable react/no-unknown-property */
/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import BaseDatagrid, { headerRenderer as HeaderRenderer } from 'react-data-grid';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Pagination } from "@mui/material";

import Styles from './DataGrid.module.css';
import { RowAllSelectProvider } from "./DataGridSelect";
import { ExpandRowProvider } from "./ExpandTreeGrid";

const linearize = (rows, added = false) => rows.reduce((acc, v) => {
	acc.push({ ...v, added, isExpanded: true });
	if (v.children) acc.push(...linearize(v.children, true));
	return acc;
}, []);

const countChild = (rows) => rows.length + rows.reduce((acc, v) => acc + (v.isExpanded ? countChild(v.children ?? []) : 0), 0);

function toggleAllRow(rows, value) {
	if (value) return linearize(rows.filter((r) => !r.added).map((d) => ({ ...d, isExpanded: false })));
	return rows.filter((r) => !r.added).map((d) => ({ ...d, isExpanded: false }));
}

function toggleSubRow(rows, id, value) {
	const rowIndex = rows.findIndex((r) => r.id === id);
	const row = rows[rowIndex];
	const { children } = row;
	if (!children) return rows;

	const newRows = [...rows];
	newRows[rowIndex] = { ...row, isExpanded: value === undefined ? !row.isExpanded : value };
	if (value === undefined ? !row.isExpanded : value) {
		newRows.splice(rowIndex + 1, 0, ...children.map((c) => ({ ...c, added: true })));
	} else {
		newRows.splice(rowIndex + 1, countChild(children));
	}
	return newRows;
}

function reducer(rows, { type, id, data, value }) {
	switch (type) {
		case 'toggleSubRow':
			return toggleSubRow(rows, id);
		case 'toggleAll':
			return toggleAllRow(rows, value);
		case "hydratate":
			return data;
		default:
			return rows;
	}
}


export default function DataGrid({
	rows, columns, onSortColumnsChange, setAllSelectionModal, setAllDeselectionModal, allSelected,
	rowKeyGetter, selectedRows, setSelectedRows, query, totalResults, setPage, currPage, sortData, onSortDataChange
}) {
	const [intRows, dispatch] = useReducer(reducer, rows);
	const tabRef = useRef();

	const [isMouseDown, setIsMouseDown] = useState(false);
	const [dragAllowed, setDragAllowed] = useState(false);
	const mouseCoords = useRef({ startX: 0, startY: 0, scrollLeft: 0, scrollTop: 0 });
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		if (!tabRef?.current?.element) return () => {};

		const mousedown = (e) => {
			const ele = tabRef?.current?.element;
			if (!tabRef?.current?.element) return;
			if (!dragAllowed) return;
			//console.log(tabRef?.current?.element);
			const startX = e.pageX - tabRef?.current?.element.offsetLeft;
			const startY = e.pageY - tabRef?.current?.element.offsetTop;
			mouseCoords.current = { startX, startY };
			setIsMouseDown(true);
		};

		const mouseup = () => {
			setIsMouseDown(false);
		};

		const onMouseMove = (e) => {
			if (!isMouseDown || !tabRef?.current?.element) return;
			if (!dragAllowed) return;
			e.preventDefault();
			const x = e.pageX - tabRef?.current?.element.offsetLeft;
			const y = e.pageY - tabRef?.current?.element.offsetTop;
			const walkX = (x - mouseCoords.current.startX);
			const walkY = (y - mouseCoords.current.startY);
			const startX = e.pageX - tabRef?.current?.element.offsetLeft;
			const startY = e.pageY - tabRef?.current?.element.offsetTop;
			mouseCoords.current = { startX, startY };
			tabRef.current.element.scrollLeft -= walkX;
			tabRef.current.element.scrollTop -= walkY;
		};

		const shortCutUp = (e) => {
			setDragAllowed(false);
			document.body.style.cursor = 'auto';
			document.body.style.userSelect = 'auto';
		};

		const shortCutDown = (e) => {
			if (e.keyCode === 17) {
				setDragAllowed(true);
				document.body.style.cursor = 'grabbing';
				document.body.style.userSelect = 'none';
			}
		};

		tabRef?.current?.element.addEventListener('mousedown', mousedown);
		tabRef?.current?.element.addEventListener('mouseup', mouseup);
		tabRef?.current?.element.addEventListener('mousemove', onMouseMove);
		tabRef?.current?.element.addEventListener('keydown', shortCutDown);
		tabRef?.current?.element.addEventListener('keyup', shortCutUp);

		return () => {
			tabRef?.current?.element.removeEventListener('mousedown', mousedown);
			tabRef?.current?.element.removeEventListener('mouseup', mouseup);
			tabRef?.current?.element.removeEventListener('mousemove', onMouseMove);
		};
	}, [isScrolling, isMouseDown, tabRef, dragAllowed]);

	useEffect(() => dispatch({ type: 'hydratate', data: rows }), [JSON.stringify(rows)]);

	function IntHeaderRenderer({ isCellSelected, column, children, onColumnsReorder, ...rest }) {
		const [{ isDragging }, drag] = useDrag({
			type: 'COLUMN_DRAG',
			item: { key: column.key },
			collect: (monitor) => ({
				isDragging: monitor.isDragging()
			})
		});

		const [{ isOver }, drop] = useDrop({
			accept: 'COLUMN_DRAG',
			drop({ key }) {
				if (column.draggable) onColumnsReorder(key, column.key);
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop()
			})
		});

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					opacity: isDragging ? 0.5 : 1,
					backgroundColor: isOver ? '#ececec' : undefined,
					cursor: 'move'
				}}
				ref={(ref) => {
					drag(ref);
					drop(ref);
				}}
			>
				<div style={{ justifySelf: 'flex-start', borderBottom: '2px solid #e8e8e8' }}>
					{children}
				</div>
			</div>
		);
	}

	const intColumns = useMemo(() => {
		const handleColumnsReorder = (sourceKey, targetKey) => {
			const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
			const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
			const reorderedColumns = [...columns];

			reorderedColumns.splice(
				targetColumnIndex,
				0,
				reorderedColumns.splice(sourceColumnIndex, 1)[0]
			);

			onSortColumnsChange(reorderedColumns);
		};

		return columns.filter((c) => c?.key).map((col) => ({
			key: col.key,
			name: col.name,
			width: col.width,
			minWidth: col.minWidth,
			frozen: col.frozen,
			formatter: !col.highlight ? (col.formatter ? (props) => col.formatter({ ...props, query }) : undefined) : (props) => (
				<Highlighter searchWords={query} autoEscape textToHighlight={col.formatter?.(props) ?? props.row[props.column.key]} />
			),
			draggable: col.draggable,
			sortable: col.sortable,
			resizable: col.resizable,
			headerCellClass: 'filter-header',
			headerRenderer: col.headerRenderer
				? (p) => (
					<IntHeaderRenderer {...p} onColumnsReorder={handleColumnsReorder}>
						<col.headerRenderer {...p} />
					</IntHeaderRenderer>
				) : (p) => (
					<IntHeaderRenderer {...p} onColumnsReorder={handleColumnsReorder}>
						<HeaderRenderer {...p} />
					</IntHeaderRenderer>
				)
		}));
	}, [columns]);

	const totalPages = Math.ceil(totalResults / 20);

	const otherPageSelected = useMemo(() => intRows.some((r) => !selectedRows?.has(r.id)), [selectedRows, intRows]);
	const currentPageSelected = useMemo(() => intRows.reduce((acc, r) => acc + (selectedRows?.has(r.id) ? 1 : 0), 0), [selectedRows, intRows]);

	return (
		<div style={{ width: "100%", display: "flex", flexDirection: "column" }} className={dragAllowed ? "no-select" : ""}>
			<ExpandRowProvider value={{ dispatch }}>
				<RowAllSelectProvider
					value={{
						allSelected,
						otherPageSelected,
						selectedCount: selectedRows?.size ?? 0,
						setAllSelectionModal,
						setAllDeselectionModal,
						totalResults,
						currentPageSelected,
						pageSelected: intRows?.filter((r) => selectedRows?.has(rowKeyGetter(r))).length,
						pagesCount: totalPages
					}}
				>
					<DndProvider backend={HTML5Backend}>
						<BaseDatagrid
							className={dragAllowed ? `${Styles.grid} ${Styles.noselect}` : `${Styles.grid}`}
							columns={intColumns}
							rows={intRows}
							direction="ltr"
							rowClass={(row) => Styles.row}
							defaultColumnOptions={{
								draggable: true,
								resizable: true
							}}
							renderers={{
								sortIcon: ({ sortDirection, priority }) => {
									if (sortDirection === "DESC") return <i className="fa fa-sort-down" />;
									if (sortDirection === "ASC") return <i className="fa fa-sort-up" />;
									return <i className="fa fa-sort" />;
								}
							}}
							rowHeight={45}
							enableFilterRow={false}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
							rowKeyGetter={rowKeyGetter}
							sortColumns={sortData}
							onSortColumnsChange={onSortDataChange}
							ref={tabRef}
						/>
					</DndProvider>
				</RowAllSelectProvider>
			</ExpandRowProvider>
			<div className="d-flex flex-row">
				<div style={{ alignSelf: "start", width: "400px", paddingTop: "20px", color: "#a2a2a2", fontSize: "16px" }}>Tieni premuto CTRL e trascina per muovere la tabella.</div>
				<Pagination sx={{ marginTop: "20px", marginLeft: "auto" }} onChange={(e, p) => setPage(p - 1)} page={currPage + 1} count={totalPages} />
			</div>
		</div>
	);
}
