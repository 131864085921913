import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../Hooks/useFetch';
import configuration from "../../../configuration";
import useToken from "../../../Hooks/useToken";

export default function TributoSelect({ disabled, onChange, value, required, ente }) {
	const { data: tributi } = useFetch(`/tributo/${ente}/list`, null, 'GET');
	const jwt = useToken();

	return tributi && (
		<Autocomplete
			disablePortal
			autoHighlight
			disabled={disabled}
			id="combo-box-demo"
			className="w-100 mt-2"
			options={tributi ? tributi.data.map((tributo) => ({
				label: tributo.denominazione,
				codiceTributo: tributo.codiceTributo,
				...tributo
			})) : []}
			isOptionEqualToValue={(option, v) => option.codiceTributo === v}
			onChange={async (event, item) => {
				if (!item) {
					onChange(null);
					return;
				}

				const response = await fetch(`${configuration.serverAddress}/tributo/${item.codiceEnte}/${item.codiceTributo}/all`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				onChange(await response.json());
			}}
			value={value?.codiceTributo ?? value ?? null}
			renderOption={(props, option) => (
				<li {...props} key={option.codiceTributo}>{option.label}</li>
			)}
			renderInput={(fieldParams) => (
				<TextField {...fieldParams} required={required} label="Tipologia Pagamento" variant="standard" />
			)}
			ListboxProps={{ style: { maxHeight: '250px' } }}
			noOptionsText="Nessuna Opzione"
		/>
	);
}
