/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";
import { Button } from "design-react-kit";
import { Controller, useForm, FormProvider } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as yup from "yup";

import { yupResolver } from '@hookform/resolvers/yup';
import moment from "moment";

import useToken from "../../Hooks/useToken";

import { addToCart } from "../../Store/Cart";

import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleBar from '../Components/Common/TitleBar';
import ModaleServizio from '../Components/CarrelloPagamento/ModaleServizio';
import InputConDescrizione from '../Components/Common/InputConDescrizione';
import { useFetch } from "../../Hooks/useFetch";
import PluginManager from "../../Plugins/PluginManager";
import configuration from "../../configuration";
import handleErrors from "../../Backoffice/Utils/HandleErrors";
import HierarchySelect from "../Components/Common/HierarchySelect";
import { getRootStructures } from "../Components/Strutture/deepMapping";
import DataViewer from "../Components/Common/DataViewer";
import Paginazione from "../Components/Common/Paginazione";
import Captcha from "../Components/Common/Captcha";
import SpinnyPage from "../../Backoffice/Components/SpinnyPage";
import PortalInputMap from "../../Plugins/Campi/Interfaces/PortalInputMap";
import NotFound from './ErrorPage';

import { defaultPaymentFormValidator, vCdr, vPrivacy } from "../../DataModels/DebitoSpontaneo";


function Form({ tributo, struttura }) {
	const [isOpen, setIsOpen] = useState(false);
	const [datiDebito, setDatiDebito] = useState(null);
	const [toCart, setToCart] = useState(false);
	const [captcha, setCaptcha] = useState(null);
	const user = useSelector((state) => state.authentication.user);
	const cart = useSelector((state) => state.cart);
	const captchaRef = useRef();
	const dispatch = useDispatch();
	const history = useHistory();
	const jwt = useToken();

	// - Refresh del captcha quando si chiude il modale di riepilogo
	useEffect(() => {
		if (!isOpen) {
			captchaRef.current?.reset();
			setCaptcha(null);
		}
	}, [isOpen]);

	// - Prendo tutti i plugin del tributo e li inizializzo
	const pluginManager = new PluginManager();
	const plugins = tributo?.plugins.map((conf) => pluginManager.getPlugin(conf.codicePlugin, conf));

	const hasPluginCampi = plugins.filter((plugin) => plugin.configuration.codicePlugin === "CAMPI").length > 0;
	const hasStrutture = tributo.struttureAmmesse.length > 0;

	const baseValidator = {
		privacy: vPrivacy,
		...(hasPluginCampi ? {} : defaultPaymentFormValidator.fields),
		...(hasStrutture ? { cdr: vCdr } : {})
	};

	// - Prepara il validatore per il form tenendo conto dei plugin
	const formValidator = useMemo(() => plugins.reduce((validator, plugin) => yup.object({
		...validator.fields, ...plugin.portalPaymentValidation(tributo).fields
	}), yup.object(baseValidator)), []);

	const paymentForm = useForm({
		reValidateMode: "onSubmit",
		defaultValues: {
			...formValidator.getDefault(),
			debitore: {
				personaFisica: true,
				nome: user?.intestatario?.nome ?? "",
				cognome: user?.intestatario?.cognome ?? "",
				cfIva: user?.intestatario?.codiceFiscale ?? ""
			},
			comune: user?.intestatario?.comune ?? "",
			indirizzo: user?.intestatario?.indirizzo ?? "",
			cdr: struttura
		},
		resolver: yupResolver(formValidator)
	});

	if (paymentForm.formState.errors) {
		console.log(paymentForm.formState.errors);
	}

	// - API Call per la creazione del debito
	const createDebit = useCallback(async (data) => {
		// - Plugins pre submit elaboration
		const pluginElaboration = (reqData, plugin) => plugin.portalPaymentSubmit(reqData);
		const requestData = plugins.reduce(pluginElaboration, { formValues: data, pluginData: [] });

		// - Reduce complex data into strings
		const { formValues } = requestData;
		const finalData = { pluginData: requestData.pluginData, formValues: Object.keys(formValues).reduce((fData, field) => {
			fData[field] = typeof formValues[field] === 'object' ? JSON.stringify(formValues[field]) : formValues[field];
			return fData;
		}, {}) };

		const response = await fetch(`${configuration.serverAddress}/debiti/spontaneo/${tributo.ente.codiceEnte}/${tributo.codiceTributo}`, {
			method: "POST",
			body: JSON.stringify(finalData),
			headers: new Headers({
				...configuration.defaultHeaders,
				...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
				...(captcha ? { Captcha: captcha } : {})
			})
		});

		if (!response.ok) {
			captchaRef.current?.reset();
			setCaptcha(null);
			if (response.status === 400) {
				const error = await response.json();
				handleErrors(error, paymentForm.setError);
			} else if (response.status === 403) {
				const error = await response.json();
				paymentForm.setError("general", { title: "Non autorizzato", description: error.messaggio });
			} else {
				paymentForm.setError("general", { title: "Errore generico", description: "Il server ha generato un errore inaspettato." });
			}

			return null;
		}

		return response.json();
	}, [paymentForm.setError, plugins]);

	// - Submit del form base
	const submitFunction = useCallback(async (data) => {
		// - Chiama la logica per creare il debito
		const response = await createDebit(data);

		if (response != null) {
			setDatiDebito(response);
			setIsOpen(true);
		}
	}, [setDatiDebito, setIsOpen, createDebit]);

	// - Submit del form per l'aggiunta al carrello
	const addToCartHandler = useCallback(async (data) => {
		// - Chiama la logica per creare il debito
		const response = await createDebit(data);

		if (response != null) {
			dispatch(addToCart({ ...response.pendenze[0], ...response }));
			history.push("/area-personale/carrello");
		}
	}, [history, dispatch, createDebit]);

	const breadcrumbs = [
		{ titolo: 'enti' },
		{ titolo: `ente ${tributo.ente.intestatario.denominazione}` }
	];

	useEffect(() => {
		if (tributo?.struttureAmmesse?.length === 1) {
			paymentForm.setValue("cdr", tributo?.struttureAmmesse[0]?.cdr);
		}
	}, [tributo]);

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleBar
				titolo={tributo.denominazione}
				sottotitolo={tributo.ente.intestatario.denominazione}
				contenutoAddizionale="Inserire le informazioni del debitore."
				shortColumn={false}
			/>
			<section className="section-pagamento section section-inset-shadow pb-0 pt-2 pb-5">
				<form onSubmit={paymentForm.handleSubmit(toCart ? addToCartHandler : submitFunction)}>
					<FormProvider {...paymentForm}>
						<div className="container pt-4 pb-4 px-0 px-md-3" id="content">
							<div className="row mt-4">
								<div className="col-12">
									<div className="row">
										{
											tributo?.struttureAmmesse?.length > 1 ? (
												<div className={`col-12 col-md-9 pt-3 bootstrap-select-wrapper input-con-descrizione w-100 `}>
													<Controller
														name="cdr"
														render={({ field: { onChange, value } }) => (
															<HierarchySelect
																trees={getRootStructures(tributo?.struttureAmmesse)}
																title="Struttura organizzativa"
																error={paymentForm.formState.errors?.cdr?.message}
																name="cdr"
																required
																onChange={(v) => onChange(v.cdr)}
																selected={tributo?.struttureAmmesse.find((s) => s.cdr === value)}
																labelProp="nome"
																keyProp="cdr"
																filterEnableOptions={(e) => !tributo?.struttureAmmesse.find((x) => x.cdr === e.cdr)}
															/>
														)}
													/>
												</div>
											) : tributo?.struttureAmmesse?.length === 1 ? (
												<Controller
													name="cdr"
													render={({ field: { value } }) => (
														<PortalInputMap.CampoStringa
															name="cdr"
															error={paymentForm.formState.errors?.cdr}
															value={value}
															label="Struttura organizzativa"
															visible={false}
														/>
													)}
												/>
											) : <></>
										}
										{!hasPluginCampi && (
											<div className="w-100">
												<Controller
													name="importo"
													render={({ field: { onChange, value } }) => (
														<PortalInputMap.CampoImporto
															error={paymentForm.formState.errors?.importo}
															value={value}
															min={0.01}
															onChange={onChange}
															label="Importo"
															suggerimento="Esempio: 100,00"
															placeholder="Inserire l'importo"
															obbligatorio
															visible
															scrollTo={"importo" in paymentForm.formState.errors}
														/>
													)}
												/>
												<Controller
													name="causale"
													render={({ field: { onChange, value } }) => (
														<PortalInputMap.CampoStringa
															error={paymentForm.formState.errors?.causale}
															value={value}
															onChange={onChange}
															label="Causale"
															suggerimento="Esempio: Versamento"
															placeholder="Inserire la causale"
															obbligatorio
															visible
															scrollTo={"causale" in paymentForm.formState.errors}
														/>
													)}
												/>
												<Controller
													name="debitore"
													render={({ field: { onChange, value } }) => (
														<PortalInputMap.CampoCFIVA
															error={paymentForm.formState.errors?.debitore}
															value={value}
															tipo="PersonaFisica"
															onChange={onChange}
															obbligatorio
															visible
															scrollTo={"debitore" in paymentForm.formState.errors}
														/>
													)}
												/>
												<Controller
													name="comune"
													render={({ field: { onChange, value } }) => (
														<PortalInputMap.CampoStringa
															error={paymentForm.formState.errors?.comune}
															value={value}
															onChange={onChange}
															label="Comune di residenza"
															placeholder="Inserire il comune di residenza"
															obbligatorio
															visible
															scrollTo={"comune" in paymentForm.formState.errors}
														/>
													)}
												/>
												<Controller
													name="indirizzo"
													render={({ field: { onChange, value } }) => (
														<PortalInputMap.CampoStringa
															error={paymentForm.formState.errors?.indirizzo}
															value={value}
															onChange={onChange}
															label="Indirizzo di residenza"
															placeholder="Inserire l'indirizzo di residenza"
															obbligatorio
															visible
															scrollTo={"indirizzo" in paymentForm.formState.errors}
														/>
													)}
												/>
											</div>
										)}
										{plugins.map((plugin) => <plugin.portalPaymentForm key={plugin.configuration.codicePlugin} config={plugin.configuration} />)}
										<Controller
											name="noteVersante"
											render={({ field: { onChange, value } }) => (
												<PortalInputMap.CampoStringa
													error={paymentForm.formState.errors?.note}
													value={value}
													onChange={onChange}
													label="Note"
													placeholder="Inserire eventuali note"
													visible
												/>
											)}
										/>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="form-check">
												<div className="mt-5 mb-3">* I campi con l&rsquo;asterisco sono obbligatori</div>
												<input id="checkbox-privacy" type="checkbox" {...paymentForm.register("privacy")} />
												<label htmlFor="checkbox-privacy">
													Dichiaro di aver letto l’informativa sulla
													privacy e do il consenso al trattamento dei dati personali.
												</label>
												{
													paymentForm.formState.errors?.privacy && (
														<div className="alert alert-danger" role="alert">
															{paymentForm.formState.errors?.privacy.message}
														</div>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							{
								paymentForm.formState.errors?.general && (
									<div className="alert alert-danger" role="alert">
										<b>{`${paymentForm.formState.errors?.general.title}: `}</b>
										{paymentForm.formState.errors?.general.description}
									</div>
								)
							}

							<div className="row mt-4">
								<div className="col-12">
									<h2 className="mb-3">Pagamento</h2>
									<div>
										{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
										<button type="submit" onClick={() => { paymentForm.clearErrors(); setToCart(false); }} className="btn btn-primary mr-4" disabled={!user && !captcha} title="Paga adesso">
											Paga
										</button>
										<button type="submit" onClick={() => { paymentForm.clearErrors(); setToCart(true); }} className="btn btn-outline-primary" disabled={cart.length >= 5 || (!user && !captcha)}>
											{cart.length >= 5 ? "Il carrello è pieno" : "Aggiungi al carrello"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</FormProvider>
				</form>
			</section>
			<ModaleServizio
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				paymentData={datiDebito}
			/>
		</>
	);
}

function Debiti({ tributo }) {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const filters = {
		tributo: { codiceTributo: tributo.codiceTributo },
		pendenze: [
			{
				pagata: false,
				escludiSolUnicaRate: true
			}]
	};
	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);

	const isDesktop = useMediaQuery("(min-width: 1441px)");
	const isMobileL = useMediaQuery("(min-width: 425px)");

	const { data } = useFetch('/debiti/user', null, 'POST', {
		pageSize,
		page: page - 1,
		...filters,
		spontaneo: false,
		stato: "Eseguito"
	});

	const statoPagamento = (d) => (
		<span
			className="stato-pagamento"
			style={{ background: d.inCorso ? "#004573" : d.pagata ? "green" : "gray" }}
		>
			{ d.inCorso
				? "In Corso"
				: d.pagata
					? "Pagato"
					: moment(d.fineValidita).isBefore()
						? "Non più pagabile"
						: moment(d.inizioValidita).isAfter()
							? "Non ancora pagabile"
							: "Da pagare"}
		</span>
	);

	const tableHead = ["Scadenza", "Causale", "Importo", "Stato", ""];

	const tableData = data?.data.length > 0
		? data.data.map((c) => (
			{
				scadenza: [moment(c.pendenze[0].scadenza).format("DD/MM/yyyy"), "normal"],
				causale: [c.pendenze[0].causale, "large"],
				importo: [`${c.pendenze[0].dettagli.reduce((acc, v) => v.importo + acc, 0)} €`, "normal"],
				stato: [statoPagamento(c.pendenze[0]), "normal"]
			}))
		: [];

	const tableButtons = data?.data.length > 0
		? data?.data.map((c) => (
			{
				button: (c.pagata
					|| c.inCorso
					|| moment(c.fineValidita).isBefore()
					|| moment(c.inizioValidita).isAfter())
					? {}
					: {
						text: "Paga",
						function: () => setIsOpenServ(c)
					}
			})) : [];

	const breadcrumbs = [
		{ titolo: 'enti' },
		{ titolo: `pagamenti per ente ${tributo?.ente.intestatario.denominazione}` }
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleBar
				titolo={tributo?.denominazione}
				sottotitolo={tributo?.ente.intestatario.denominazione}
				shortColumn={false}
			/>
			<div className="bg-white">
				<div className="section section-inset-shadow">
					<div className="container pt-4 pb-4 px-0 px-md-3" style={isDesktop ? { } : (isMobileL ? { paddingRight: "20px" } : {})}>
						<DataViewer head={tableHead} data={tableData} buttons={tableButtons} />
						<Paginazione
							paginaCorrente={data?.currentPage + 1}
							perPagina={pageSize}
							risultatiTotali={data?.totalResults}
							onPageChange={(p) => setPage(p)}
						/>
						<ModaleServizio
							isOpen={isOpenServ != null}
							setIsOpen={() => { setIsOpenServ(null); }}
							paymentData={isOpenServ}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

function RicercaDebiti({ tributo }) {
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [noResult, setNoResult] = useState(null);
	const {	handleSubmit, formState: { errors }, clearErrors, control } = useForm();

	const [captcha, setCaptcha] = useState(null);

	const captchaRef = useRef();

	const isDesktop = useMediaQuery("(min-width: 1441px)");
	const isLaptop = useMediaQuery("(max-width: 1024px)");
	const isMobileLarge = useMediaQuery("(max-width: 425px)");
	const ente = tributo.ente.codiceEnte;

	useEffect(() => {
		if (!isOpenServ) {
			captchaRef.current?.reset();
			setCaptcha(null);
		}
	}, [isOpenServ]);

	const getPagamento = (data) => {
		setNoResult(null);
		if (ente && data.codice && data.codiceFiscale) {
			const axiosParams = {
				method: "GET",
				url: `/api/debiti/iuv/${ente}/${data.codice}?cod_fiscale=${data.codiceFiscale}`,
				headers: {
					"Content-Type": "application/json",
					...(captcha ? { Captcha: captcha } : {})
				}
			};
			axios(axiosParams)
				.then((response) => {
					const pagamento = response.data;
					setNoResult(false);
					setIsOpenServ(pagamento);
				})
				.catch((e) => {
					if (e.response.status !== 403) setNoResult(true);
					captchaRef.current.reset();
				});
		} else {
			setNoResult(true);
		}
	};

	const form = (
		<form onSubmit={handleSubmit(getPagamento)}>
			<div className="container pt-4 pb-4 px-0 px-md-3 mr-auto ml-auto">
				<div className="carrello-info">
					<div className="carrello-info-text">
						{"Inserisci i seguenti dati oppure "}
						<Link style={{ color: "blue" }} to="/autenticazione">
							autenticati
						</Link>
					</div>
				</div>
				<div className="row">
					<div className={isLaptop ? "pt-4 pl-4 pr-4 pb-4 flex-grow-1" : "pt-4 pl-4 pr-4 pb-4"}>
						<Controller
							name="codiceFiscale"
							control={control}
							defaultValue=""
							rules={{
								required: true
							}}
							render={({ field: { onChange } }) => (
								<InputConDescrizione
									titolo=""
									nome="codiceFiscale"
									tipo="text"
									placeholder="Codice Fiscale oppure Partita Iva"
									onChange={(e) => {
										e.target.value = e.target.value.toUpperCase();
										onChange(e.target.value);
									}}
								/>
							)}
						/>
						{errors?.codiceFiscale && <div role="alert"><p style={{ color: "red" }} className="my-1">Campo obbligatorio</p></div>}
					</div>
					<div className="pt-4 pl-4 pr-4 pb-4 flex-grow-1">
						<Controller
							name="codice"
							control={control}
							defaultValue=""
							rules={{
								required: true
							}}
							render={({ field: { onChange } }) => (
								<InputConDescrizione
									titolo=""
									nome="codice"
									tipo="text"
									placeholder="Codice Avviso oppure IUV"
									onChange={onChange}
								/>
							)}
						/>
						{errors?.codice && <div role="alert"><p style={{ color: "red" }} className="my-1">Campo obbligatorio</p></div>}
					</div>
					<div className={isMobileLarge ? "flex-grow-1 pt-4 pl-4 pr-4 pb-4" : "ml-4 mt-2 pt-4 pl-4 pr-4 pb-4"}>
						<Button style={isMobileLarge ? { width: "100%" } : {}} color="primary" type="submit" onClick={() => { clearErrors(); }}>
							Cerca
						</Button>
					</div>
				</div>
				<Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />
			</div>
		</form>
	);

	const breadcrumbs = [
		{ titolo: 'enti' },
		{ titolo: `ricerca per tributo ${tributo?.denominazione}` }
	];

	return (
		<div>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleBar
				titolo={tributo?.denominazione}
				sottotitolo={tributo?.ente.intestatario.denominazione}
				shortColumn={false}
			/>
			<section className="section section-inset-shadow pb-0 pt-2 pb-5">
				<div>{form}</div>
				{noResult === true && (
					<div className="container p-0">
						<div className="row my-4">
							<div className="col-12 pt-4 pl-4 pr-4 pb-4">Nessun risultato</div>
						</div>
					</div>
				)}
			</section>
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
			/>
		</div>
	);
}

export default function ServizioPagamento() {
	// - Scroll up della pagina
	useEffect(() => window.scrollTo(0, 0), []);

	const { ente, servizio, struttura } = useParams();
	const user = useSelector((state) => state.authentication.user);

	// - Fetch le informazioni del tributo
	const { data: tributo, errors } = useFetch(`/tributo/${ente}/${servizio}`, null, "GET");

	if (errors?.codice === 404) {
		return <NotFound />;
	}
	if (!tributo) return (<div style={{ minHeight: "100vh" }}><SpinnyPage /></div>);
	return tributo?.spontaneo
		? <Form tributo={tributo} struttura={struttura} />
		: (user ? <Debiti tributo={tributo} /> : <RicercaDebiti tributo={tributo} />);
}
